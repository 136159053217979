<template>
  <div>
    <TimePicker v-model="time" />

    <br />

    <p>When changed to a valid time _ _ :_ _ : _ _,</p>
    <p>for example: 00:00:00, the time picker updates</p>

    <b-field :type="inputType">
      <b-input input-1 @input="inputChange" v-model="iValue" />
    </b-field>
  </div>
</template>

<script>
import TimePicker from '@/components/inputs/TimePicker'
import { timeIsInvalid } from '@/utilities/time/isInvalid'

export default {
  components: {
    TimePicker
  },
  data() {
    return {
      iValue: '',
      time: '',
      inputType: ''
    }
  },
  methods: {
    inputChange() {
      if (!timeIsInvalid(this.iValue)) {
        this.time = this.iValue
        this.inputType = ''
      } else {
        console.error('time is invalid', this.iValue)
        this.inputType = 'is-danger'
      }
    }
  }
}
</script>
