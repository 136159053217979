<template>
  <div :style="{ padding: '2in' }">
    <Example1 />
  </div>
</template>

<script>
/*
  /management/_guides/TimePicker

*/

import Example1 from './1'

export default {
  components: {
    Example1
  }
}
</script>
