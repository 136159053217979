/*
	import { timeIsInvalid } from "@/utilities/time/isInvalid"

	const a = "22:00:00";

	const isntValid = timeIsInvalid (a;


	// results:
	//   0 -> isntValid -> the time is valid
	//   1,2,... -> the time isn't valid 
*/

/*
	00:00:00 to 23:59:59

	hours: 00 to 25
		00:00:00 is equivalent to 24:00:00

	minutes: 00 to 59
	seconds: 00 to 59
*/
export function timeIsInvalid(t) {
  try {
    if (typeof t !== 'string') {
      return 1
    }

    if (t.length !== 8) {
      return 2
    }

    /*
			make sure [i,i,c,i,i,c,i,i]
				int
				colon

			c -> cursor
		*/
    for (let c = 0; c <= 7; c++) {
      if ([0, 1, 3, 4, 6, 7].includes(c)) {
        if (!Number.isInteger(parseInt(t[c]))) {
          return 3
        }
      }

      if ([2, 5].includes(c)) {
        if (t[c] !== ':') {
          return 4
        }
      }
    }

    // s -> split
    const s = t.split(':').map(e => parseInt(e))

    // This check may never occur?
    for (let c = 0; c <= 2; c++) {
      if (!Number.isInteger(s[c])) {
        return 5
      }
    }

    if (s[0] >= 25 || s[0] <= -1) {
      return 6
    }
    if (s[0] === 24) {
      if (s[1] !== 0 || s[2] !== 0) {
        return 7
      }
    }

    if (s[1] >= 60 || s[1] <= -1) {
      return 8
    }

    if (s[2] >= 60 || s[2] <= -1) {
      return 9
    }

    return 0
  } catch (x) {
    console.error(x)
  }

  return 10
}
